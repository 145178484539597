'use client'

import Custom404 from '@/components/ErrorMessages/Custom404'
import {useEffect} from 'react'

export default function Error({error}: {error: Error & {digest?: string}}) {
  useEffect(() => {
    console.error(error)
  }, [error])

  return <Custom404 />
}
