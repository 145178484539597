import Image from 'next/image'

const Custom404 = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#F4F4F5] gap-4 pb-12">
      <Image
        src="/images/404_illustration.png"
        alt="404 Not Found"
        className="w-40"
        width={601}
        height={658}
      />
      <p className="mt-5 text-xl font-semibold">Uh oh ...</p>
      <p className="text-xl font-semibold">This page cannot be found.</p>
    </div>
  )
}

export default Custom404
